export const getMapCenterForLocale = (locale = "en-us"): [number, number] => {
  const countryCode = locale.toLowerCase().split("-")[1];
  switch (countryCode) {
    case "ca":
      // Canada map center
      return [-93.3925, 56];
    default:
      // U.S. map center
      return [-95.7129, 40];
  }
};

export function localeAllowsShipping(locale: string): boolean {
  const countryCode = locale.toLowerCase().split("-")[1];
  switch (countryCode) {
    case "us":
      return true;
    default:
      return false;
  }
}

export function localeMediaKit(locale: string): string {
  const countryCode = locale.toLowerCase().split("-")[1];
  switch (countryCode) {
    case "ca":
      return "https://cmbl.co/canada-press";
    default:
      return "https://cmbl.co/prsF2K";
  }
}

export function localeSocialLinks(locale: string): { [key: string]: string } {
  const countryCode = locale.toLowerCase().split("-")[1];
  switch (countryCode) {
    case "ca":
      return {
        facebook: "https://facebook.com/crumbl.ca",
        twitter: "https://twitter.com/crumblcookies",
        instagram: "https://www.instagram.com/crumbl.ca/",
        tiktok: "https://www.tiktok.com/@crumbl.ca",
        youtube: "https://www.youtube.com/c/CrumblCookies",
        linkedin: "https://www.linkedin.com/company/crumblcookies/",
        pinterest: "https://www.pinterest.com/crumblcookies/"
      };
    default:
      return {
        facebook: "https://www.facebook.com/crumblcookies/",
        twitter: "https://twitter.com/crumblcookies",
        instagram: "https://instagram.com/crumblcookies",
        tiktok: "https://www.tiktok.com/@crumblcookies",
        youtube: "https://www.youtube.com/c/CrumblCookies",
        linkedin: "https://www.linkedin.com/company/crumblcookies/",
        pinterest: "https://www.pinterest.com/crumblcookies/"
      };
  }
}

export function localeGTM(locale: string): string {
  const countryCode = locale.toLowerCase().split("-")[1];
  switch (countryCode) {
    // case "ca":
    // return "GTM-PXHZ44G";
    default:
      return "GTM-K24T5LG";
  }
}

export const getRegionCode = (locale: string): string => {
  switch (locale.toLowerCase()) {
    case "en-ca":
      return "CA";
    default:
      return "US";
  }
};
