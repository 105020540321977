export const isOrderId = (id?: string): boolean => {
  // @ts-ignore
  return id?.endsWith(":Orders") || id?.length > 20;
};

import type { Order, OrderError } from "@/generated/requests/pos";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useEffect } from "react";
import { trackFBPurchase } from "../static/lib/facebook";
import { logPurchase } from "../static/lib/firebase";
import { track } from "../static/lib/tracking";
import { centsToDecimal, fetchLocal, formatPhone, storeLocal } from "../static/lib/util";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

export const IMAGESTORAGE = "ImageKeyStorage";

const productsToTrack = () => {
  const products = fetchLocal("order")?.what || [];
  return products?.map(({ product, meta, price }, position) => ({
    id: product?.productId,
    name: meta?.title,
    price: price / 100,
    category: meta?.category,
    variant: meta.lineItems.join(", "),
    quantity: 1,
    position,
  }));
};

export enum CheckoutEventLabel {
  Login = "Login",
  ReviewOrder = "Review Order",
  AddNote = "Add Note",
  PickupDetails = "Pickup Details",
  Tips = "Tips",
  AddPaymentMethod = "Add Payment Method",
}

const trackCheckout = (eventLabel: CheckoutEventLabel, moreData = {}) => {
  const order = fetchLocal("order");

  track({
    event: "checkout",
    eventCategory: "Checkout",
    eventAction: "Viewed",
    eventLabel,
    ecommerce: {
      checkout: {
        actionField: {
          eventLabel,
          ...moreData,
        },
        products: productsToTrack(),
        currency: order?.totals?.total?.currency || "USD",
        amount: (order?.totals?.total?.amount || 0) / 100,
      },
    },
  });
};

// Custom hook for tracking checkout events. Include this in any component that
// needs to track checkout events and it will fire once after the component
// renders initially. Now we don't have to do complex logic to figure out what
// event to send, yay!!!
const useTrackCheckout = (eventLabel: CheckoutEventLabel, moreData = {}) => {
  useEffect(() => {
    trackCheckout(eventLabel, moreData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const trackPurchase = (actionField) => {
  const products = productsToTrack();
  const customer = fetchLocal("customer");
  track({
    orderId: actionField?.id,
    event: "ecomm_event",
    ecommerce: {
      purchase: {
        actionField,
        products,
        itemCount: products?.length || 0,
      },
    },
    eventLabel: "Purchase",
    eventValue: actionField?.revenue || 0,
    user_data: {
      email: customer?.email || "",
      phone: formatPhone(customer?.phoneNumber || ""),
    },
  });
};

const trackPurchaseWrapper = (order: Order) => {
  let flavorIds = [];
  const productIds = [];
  order.items?.forEach((w) => {
    // @ts-ignore
    flavorIds.push(...(w?.meta?.flavorIds || []));
    // @ts-ignore
    productIds.push(w.product.productId);
  });
  flavorIds = flavorIds.filter((f) => !!f);
  const contentIds = flavorIds.length ? flavorIds : productIds;
  const contentType = flavorIds.length ? "product_group" : "product";
  const total = order.totals?.total?.amount || 0;
  const currency = order.totals?.total?.currency || "USD";

  trackFBPurchase(order.orderId, centsToDecimal(total), currency, contentIds, contentType);
  logPurchase(order.orderId, currency, total, order.totals?.taxes?.amount?.amount);
  trackPurchase({
    id: order.orderId || "",
    affiliation: "WEB",
    // @ts-ignore
    type: order.type || "",
    revenue: centsToDecimal(total),
    tax: centsToDecimal(order.totals?.salesTax?.amount?.amount),
    shipping: centsToDecimal(order.totals?.shippingFee?.amount),
    delivery: centsToDecimal(order.totals?.deliveryFee?.amount),
  });
  trackPurchaseBraze(order);
};

const trackPurchaseBraze = (order: Order) => {
  const optionIds: string[] = [];
  order?.items?.forEach((item) => {
    item?.modifiers?.forEach((modifier) => {
      modifier?.options?.forEach((option) => {
        if (option?.modifierOptionId) {
          optionIds.push(option?.modifierOptionId);
        }
      });
    });
  });

  track({
    event: "order_completed",
    eventLabel: "Order Completed",
    optionIds: [...new Set(optionIds)],
    productIds: [...new Set(order?.items?.map((item) => item?.product?.productId))],
    receiptId: order?.formattedReceipt?.receiptId,
    orderId: order.orderId,
    amount: centsToDecimal(order.totals?.total?.amount),
  });
};

export const trackGiftcard = (giftCardObj) => {
  const cart = giftCardObj?.apiOrderData;
  const orderId = cart?.orderId;
  const total = cart?.totals?.total?.amount || 0;
  const tax = cart?.totals?.salesTax?.amount?.amount || 0;
  const currency = cart.totals?.total?.currency || "USD";

  trackFBPurchase(orderId, centsToDecimal(total), currency, [giftCardObj?.product?.product?.productId], "product");
  logPurchase(orderId, currency, total, tax);
  trackPurchase({
    id: orderId,
    affiliation: "WEB",
    type: "Giftcard",
    revenue: total / 100,
    tax: tax / 100,
    shipping: 0,
    delivery: 0,
  });

  storeLocal(IMAGESTORAGE, null);
};

const poll = async (checkSuccess, complete, timedOut, interval, maxAttempts = 5) => {
  if (maxAttempts == 0) {
    if (timedOut) {
      timedOut();
    }
    return;
  }
  if (await checkSuccess()) {
    complete();
    return;
  }

  setTimeout(() => {
    poll(checkSuccess, complete, timedOut, interval, maxAttempts - 1);
  }, interval);
};

export const resizeImageIfNeeded = (url = "", size = 600) => {
  if (!url) {
    return "";
  }

  if (url.includes("generated")) {
    return url;
  }

  return `https://crumbl.video/cdn-cgi/image/width=${size}/${normalizeSrc(url)}`;
};

const normalizeSrc = (src) => {
  return src?.startsWith("/") ? src.slice(1) : src;
};

export { poll, productsToTrack, trackCheckout, trackPurchase, trackPurchaseWrapper, useTrackCheckout };

export type UpsertOrderResponse = {
  errors?: OrderError[];
  order?: any;
  paymentIntent?: {
    paymentIntentId: string;
    clientSecret: string;
    amount: number;
  };
};
