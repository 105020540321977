import type { SVGProps } from "react";
const IconTruckFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M19 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0M7 17a2 2 0 1 0 0 4 2 2 0 0 0 0-4m14.75-3.42v2.92c0 .72-.34 1.35-.86 1.76a.46.46 0 0 1-.24.1c-.12 0-.23-.06-.26-.19A3.49 3.49 0 0 0 17 15.5a3.5 3.5 0 0 0-3.46 2.989.296.296 0 0 1-.29.261h-2.5a.296.296 0 0 1-.29-.261A3.5 3.5 0 0 0 7 15.5c-1.64 0-3.03 1.14-3.39 2.67-.03.12-.15.2-.28.19a.54.54 0 0 1-.22-.1c-.52-.41-.86-1.04-.86-1.76v-2.45a.3.3 0 0 1 .3-.3H10c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H2.55a.3.3 0 0 1-.3-.3V5.5A2.257 2.257 0 0 1 4.5 3.25h8a2.257 2.257 0 0 1 2.25 2.25v1.75h2.22c2.54 0 3.02 1.2 3.48 2.36l1.04 2.57c.07.19.13.39.17.58.02.06.04.13.04.2.03.2.05.41.05.62m-1.85-1.33-.85-2.08c-.42-1.06-.56-1.42-2.08-1.42h-2.22v3.5z"
    />
  </svg>
);
export default IconTruckFilled;
