import dayjs, { type Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export { dayjs, type Dayjs };

// Helper function to get the label for the date
export const getLabelForDate = (date: Dayjs, timezone: string) => {
  const today = dayjs().tz(timezone);
  const tomorrow = dayjs().tz(timezone).add(1, "day");

  if (date.isSame(today, "day")) {
    return "Today";
  } else if (date.isSame(tomorrow, "day")) {
    return "Tomorrow";
  } else {
    return date.format("ddd, MMM D");
  }
};
