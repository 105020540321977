import type { SVGProps } from "react";
const IconCurbsideRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.34 3.00997H7.77V0.999971C7.77 0.569971 7.42 0.219971 7 0.219971C6.58 0.219971 6.23 0.569971 6.23 0.999971V3.00997H1.66C0.75 3.00997 0 3.74997 0 4.66997V9.35997C0 10.27 0.74 11.02 1.66 11.02H12.35C13.26 11.02 14.01 10.28 14.01 9.35997V4.66997C14.01 3.75997 13.27 3.00997 12.35 3.00997H12.34Z"
      fill="black"
    />
    <path
      d="M9.99001 18.23H7.78001V12.48H6.23001V18.23H4.03001C3.60001 18.23 3.26001 18.58 3.26001 19.01C3.26001 19.44 3.61001 19.79 4.03001 19.79H9.99001C10.42 19.79 10.76 19.44 10.76 19.01C10.76 18.58 10.41 18.23 9.99001 18.23Z"
      fill="black"
    />
  </svg>
);
export default IconCurbsideRegular;
