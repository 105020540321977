import { LS_ORDER } from "@/components/v2/contexts/OrderContextNew/OrderContextNew";
import {
  Currency,
  CustomerCashAccountDocument,
  CustomerOrderHistoryStoresDocument,
  CustomerPaymentMethodsDocument,
  CustomerReceiptsPaginatedDocument,
  FetchOrderReceiptV2Document,
  GenericSourceForStoreDocument,
  GetVoucherDocument,
  MyActiveVouchersDocument,
  OrderDocument,
  SourceBusinessHoursDocument,
  type SourceType,
  StoreSourceForLocationSelectorDocument,
} from "@/generated/requests/pos";
import {
  FetchStoreBySlugFullDocument,
  NearbyActiveStoresForCoordinatesWithNearbyStoresDocument,
  StoreByStoreIdDocument,
  StoreSourcesByStoreIdDocument,
  UserRewardSummaryDocument,
} from "@/generated/requests/services";
import { useAPIQuery, useCustomerQuery, usePOSQuery } from "@/lib/apollo-hooks";
import { fetchLocal } from "@/static/lib/util";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const POLL_INTERVAL = 600000; // 10 minutes in milliseconds
dayjs.extend(utc);
dayjs.extend(timezone);

export const useGetOrder = () => {
  const orderId = fetchLocal<string>(LS_ORDER);
  const { data, loading, error, refetch, networkStatus } = usePOSQuery(OrderDocument, {
    variables: {
      orderOrReceiptId: orderId,
    },
    skip: !orderId,
    fetchPolicy: "cache-and-network",
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error, refetch, networkStatus };
};

export const useGetStoreBySlug = ({ storeSlug }: { storeSlug: string }) => {
  const { data, loading, error } = useAPIQuery(FetchStoreBySlugFullDocument, {
    variables: {
      slug: storeSlug,
    },
    skip: !storeSlug,
    fetchPolicy: "cache-first",
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores?.storeForSlug, loading, error };
};

export const useGetSource = ({ storeId, type, timeSlot }: { storeId: string; type: SourceType; timeSlot: string }) => {
  const { data, loading, error } = usePOSQuery(GenericSourceForStoreDocument, {
    variables: {
      storeId: storeId,
      type: type,
      pickupDate: timeSlot,
    },
    skip: !type || !storeId,
    fetchPolicy: "cache-first",
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetSourceAllowedDaysToOrder = ({
  storeId,
  sourceType,
}: {
  storeId: string;
  sourceType: SourceType;
}) => {
  const { data, loading, error } = usePOSQuery(StoreSourceForLocationSelectorDocument, {
    variables: {
      storeId: storeId,
      type: sourceType,
    },
    skip: !storeId || !sourceType,
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetStoreById = ({ storeId }: { storeId: string }) => {
  const { data, loading, error } = useAPIQuery(StoreByStoreIdDocument, {
    variables: {
      storeId: storeId,
    },
    skip: !storeId,
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores?.publicStoreInfo, loading, error };
};

export const useGetStoreSources = ({ storeId }: { storeId: string }) => {
  const { data, loading, error } = useAPIQuery(StoreSourcesByStoreIdDocument, {
    variables: {
      storeId: storeId,
    },
    skip: !storeId,
    pollInterval: POLL_INTERVAL,
  });

  return { data: data?.stores.store, loading, error };
};

export const useGetCustomerCashAccount = ({ customerId, currency }: { customerId: string; currency?: string }) => {
  const { data, loading, error } = usePOSQuery(CustomerCashAccountDocument, {
    variables: {
      currency: currency || Currency.Usd,
    },
    skip: !customerId,
  });

  return { data, loading, error };
};

export const useGetCustomerPaymentMethods = ({
  customerId,
  stripeRegion,
}: {
  customerId: string;
  stripeRegion: string;
}) => {
  const { data, loading, error, refetch } = usePOSQuery(CustomerPaymentMethodsDocument, {
    variables: {
      stripeRegion: stripeRegion || "US",
    },
    skip: !stripeRegion || !customerId,
  });

  return { data, loading, error, refetch };
};

export const useGetCustomerOrderHistoryStores = ({ customerId }: { customerId: string }) => {
  const { loading, data, error } = usePOSQuery(CustomerOrderHistoryStoresDocument, {
    skip: !customerId,
  });

  return {
    data: data?.customer?.customersOrders,
    loading,
    error,
  };
};

export const useGetVouchers = ({ customerId, timeSlot }: { customerId: string; timeSlot?: string }) => {
  const { data, loading, error } = usePOSQuery(MyActiveVouchersDocument, {
    variables: {
      pickupDate: timeSlot,
    },
    skip: !customerId,
    fetchPolicy: "cache-first",
  });

  return { data, loading, error };
};

export const useGetSourceBusinessHours = ({
  storeId,
  type,
  selectedDate,
}: {
  storeId: string;
  type: SourceType;
  selectedDate: string;
}) => {
  const { data, loading, error } = usePOSQuery(SourceBusinessHoursDocument, {
    variables: {
      storeId: storeId,
      type: type,
      selectedDate: selectedDate,
    },
    skip: !storeId || !type || !selectedDate,
    pollInterval: POLL_INTERVAL,
  });

  return { data, loading, error };
};

export const useGetReceipt = ({ receiptId }: { receiptId: string }) => {
  const { data, loading, error, refetch } = usePOSQuery(FetchOrderReceiptV2Document, {
    variables: { orderId: receiptId },
    skip: !receiptId,
    // because the receipt can change when a customer arrives, or their order is updated
    fetchPolicy: "no-cache",
  });

  return { data, loading, error, refetch };
};

export const useGetVoucher = ({ voucherId }: { voucherId: string }) => {
  const { data, loading, error } = usePOSQuery(GetVoucherDocument, {
    variables: { code: voucherId },
    skip: !voucherId,
  });

  return { data, loading, error };
};

export const useGetOrderHistoryPaginated = ({ customerId, limit = 20 }: { customerId: string; limit: number }) => {
  const { loading, data, error, fetchMore, networkStatus } = usePOSQuery(CustomerReceiptsPaginatedDocument, {
    variables: { limit: limit },
    skip: !customerId,
    notifyOnNetworkStatusChange: true,
  });

  return { data, loading, error, fetchMore, networkStatus };
};

export const useGetCustomerRewardSummary = ({
  customerId,
  pickupDate,
}: {
  customerId: string;
  pickupDate?: string;
}) => {
  const {
    data,
    loading: rewardSummaryLoading,
    refetch,
  } = useCustomerQuery(UserRewardSummaryDocument, {
    variables: {
      pickupDate,
    },
    skip: !customerId,
  });
  return { data, rewardSummaryLoading, refetch };
};

export const useGetNearbyStores = ({ latitude, longitude }: { latitude: number; longitude: number }) => {
  const { data, loading, error } = useCustomerQuery(NearbyActiveStoresForCoordinatesWithNearbyStoresDocument, {
    variables: {
      coordinates: {
        latitude,
        longitude,
      },
    },
    skip: !latitude || !longitude,
  });

  return { data: data?.stores?.publicStoreInfoSearch, loading, error };
};
