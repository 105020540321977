import { ImageLoaderProps } from "next/image";

const isDevelopment = process.env.NODE_ENV === "development";
const defaultBaseUrl = process.env.NEXT_PUBLIC_BASE_DOMAIN || "https://www.crumblcookies.biz";

const absolutePath = (src) => {
  if (src?.startsWith("/")) {
    try {
      return new URL(src, typeof window !== "undefined" ? window.document.baseURI : defaultBaseUrl).href;
    } catch {
      return src;
    }
  }
  return src;
};

export const cloudflareLoader = ({ src, width, quality = 80 }: ImageLoaderProps) => {
  if (isDevelopment) {
    if (src?.startsWith("/")) {
      return src;
    }
    return `https://crumbl.video/cdn-cgi/image/width=${width}/${src}`;
  }

  src = absolutePath(src);
  if (src?.endsWith(".svg") || src?.includes("localhost")) {
    return src;
  }

  const paramsString = [`width=${width}`, "format=auto", `quality=${quality || 80}`].join(",");

  const vercelBypass =
    src.includes("dev.crumblcookies.") || src.includes("beta.crumbl")
      ? `?x-vercel-protection-bypass=${process.env.NEXT_PUBLIC_VERCEL_BYPASS_TOKEN}`
      : "";
  return `https://crumbl.video/cdn-cgi/image/${paramsString}/${src}${vercelBypass}`;
};

export default cloudflareLoader;
